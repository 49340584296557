<mat-progress-bar mode="query" style="position: fixed;"
                  *ngIf="tabs.length == 0"></mat-progress-bar> <!--*ngIf="tabs.length == 0"-->
<div class="security-page">
  <div class="security-page__wrapper">
    <div class="security-page__container">
      <div class="users-list__top">
        <div class="users-list__top-main" style="background-color: #f8f8fa;">
          <div class="users-list__title">Пропуска</div>
          <div class="users-list__line"></div>
          <div class="security-page__top-spec-block">
            <div class="users-list__search-block">
              <div class="users-list__search-img-block">
                <img class=" images users-list__search-img" src="img/lupa.svg" alt="lupa">
              </div>
              <form class="example-form">
                <input
                  class="users-list__search-input"
                  type="text"
                  placeholder="Поиск по имени или организации"
                  [formControl]="control"
                  [matAutocomplete]="auto">
                <!-- <input class="users-list__search-input" type="text" placeholder="Поиск"> -->
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option style="display: none;" *ngFor="let x of filteredStreets | async"
                              [value]="x.organizationName">
                    {{x.organizationName}}
                  </mat-option>
                </mat-autocomplete>
              </form>
            </div>
            <!-- <div class="users-list__search-block security-page__search-block">
                <div class="users-list__search-img-block">
                    <img class=" images users-list__search-img" src="img/lupa.svg" alt="lupa">
                </div>
                <input class="users-list__search-input" type="text" placeholder="Поиск">
            </div> -->
          </div>
          <div class="security-page__top-spec-block">
            <!-- <mat-label>Enter a date range</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input #fromDate matStartDate placeholder="От" readonly>
              <input #toDate matEndDate placeholder="До" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker [touchUi]=isMobile #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Отмена</button>
                <button mat-raised-button color="primary" matDateRangePickerApply
                        (click)="dateRangee(fromDate.value, toDate.value)">Применить
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
            <!-- <a #downloadE href=""></a> -->
          </div>
        </div>
        <div class="security-page__create js-my-page__createe" *ngIf="role != 'SecurityOfficer' && role != 'Director'"
             (click)="addPassPopup()">
          <div class="security-page__create-img-block">
            <img class=" images " src="img/plus-small-add-white.svg" alt="">
          </div>
          <div class="security-page__create-text">
            Создать пропуск
          </div>
        </div>
        <div class="security-page__create js-my-page__createe" *ngIf="role == 'Director'"
             (click)="addPassDirectorPopup()">
          <div class="security-page__create-img-block">
            <img class=" images " src="img/plus-small-add-white.svg" alt="">
          </div>
          <div class="security-page__create-text">
            Создать пропуск
          </div>
        </div>
      </div>
      <div class="security-page__main">

<!--        <mat-expansion-panel style="width: 90%; margin-left: 5%">-->
<!--          <mat-expansion-panel-header>-->
<!--            <mat-panel-title>-->
<!--              Легенда-->
<!--            </mat-panel-title>-->
<!--          </mat-expansion-panel-header>-->
<!--          <div class="legend-box">-->
<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: white"></div>-->
<!--                <div class="legend-color" style="background: #ffa500"></div>-->
<!--              </div>-->
<!--              <span>ожидаем сегодня</span>-->
<!--            </div>-->

<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: darkviolet"></div>-->
<!--                <div class="legend-color" style="background: #ffa500"></div>-->
<!--              </div>-->
<!--              <span>ожидаем</span>-->
<!--            </div>-->

<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: #de37e2"></div>-->
<!--                <div class="legend-color" style="background: #ffa500"></div>-->
<!--              </div>-->
<!--              <span>не ожидаем сегодня</span>-->
<!--            </div>-->

<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: #3e94ff"></div>-->
<!--                <div class="legend-color" style="background: #3e94ff"></div>-->
<!--              </div>-->
<!--              <span>вне територии</span>-->
<!--            </div>-->

<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: green"></div>-->
<!--                <div class="legend-color" style="background: green"></div>-->
<!--              </div>-->
<!--              <span>на територии</span>-->
<!--            </div>-->

<!--            <div class="legend-item">-->
<!--              <div class="legend-color-box">-->
<!--                <div class="legend-color" style="background: red"></div>-->
<!--                <div class="legend-color" style="background: red"></div>-->
<!--              </div>-->
<!--              <span>прострочен</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </mat-expansion-panel>-->
        <!-- <div class="tab__scroll">
            <div class="tab js-tab">
                <button class="tablinks js-tablinks js-active" onclick="openPass(event, 'all')">Все</button>
                <button class="tablinks js-tablinks" onclick="openPass(event, 'favour')">Избранные(1)</button>

            </div>
        </div> -->
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="1">
          <mat-tab *ngFor="let tab of tabs; index as i" label="{{tab.name}}" [tabIndex]="i">
            <div class="tabcontent tabcontent-all">
              <div class="security-page__all">
                <div class="security-page__all-top my-page__all-top">
                  <div class="security-page__title-category w_7">
                    RFID
                  </div>
                  <div class="security-page__title-category w_22">
                    Гость
                  </div>
                  <div class="security-page__title-category w_18">
                    Способ въезда
                  </div>
                  <div class="security-page__title-category w_14">
                    Дата въезда
                  </div>
                  <div class="security-page__title-category w_14">
                    Дата выезда
                  </div>
                  <div class="security-page__title-category w_19 status">
                    Статус
                  </div>
                  <div class="security-page__title-category w_19 status" *ngIf="role == 'SecurityOfficer'">
                    Организация
                  </div>
                </div>
                <div class="security-page__all-info">
                  <div *ngFor="let checkpointPas of tab.checkpointPass">
                    <div class="security-page__item"
                         [ngClass]="{
                                                'car-group-alone' : aloneOrNot(tab.checkpointPass, checkpointPas.groupNumber) == 1 && tab.name !='Все' && tab.name !='С грузом' && checkpointPas.positionInGroup != 0,
                                                'car-group-first' : checkpointPas.positionInGroup == 1,
                                                'car-group-middle' : checkpointPas.positionInGroup == 2,
                                                'car-group-end' : checkpointPas.positionInGroup == 3,
                                                'pass-not-nowday' : !isInRange(checkpointPas.arrivalFrom),
                                                'pass-in-range' : isInRange(checkpointPas.arrivalFrom),
                                                'pass-nowday' : isNowday(checkpointPas.arrivalFrom),
                                                'pedestrian-group' : checkpointPas.status == 'Просрочен',
                                                'truck-car-group' : checkpointPas.status == 'Выдан пропуск',
                                                'car-group' : checkpointPas.status == 'Ожидается',
                                                'mixed-group' : checkpointPas.status == 'Вне территории' || checkpointPas.status == 'Пропуск сдан'}"
                         [ngStyle]="{'background': !checkpointPas.inspection ? '#464646' : '#fffff   ' }"
                    >
<!--                      checkpointPas.status == 'На территории' ||-->
                      <div class="security-page__info-item">
                        <div class="security-page__id security-page__id w_7"
                             [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.rfId}}</div>
                        <div class="security-page__guest w_20">
                          <div class="security-page__info-person-from"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            <div class="security-page__info-name-person"
                                 [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.firstName}} {{checkpointPas.lastName}} {{checkpointPas.patronymic}}</div>
                            <div class="security-page__info-document">
                              <div class="security-page__info-document-text"
                                   [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.documentType}}</div>
                              <div class="security-page__info-document-number"
                                   [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.document}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="security-page__way w_18">
                          <div class="security-page__way-icon" *ngIf="checkpointPas.driver == true">
                            <img src="/img/steering-wheel.svg">
                          </div>
                          <div class="security-page__line"
                               *ngIf="checkpointPas.type != 'Пешком' && checkpointPas.driver == true"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">I
                          </div>
                          <div class="security-page__way-icon">
                            <svg>
                              <use xlink:href="img/icons/icons.svg#by-foot-main" *ngIf="checkpointPas.type == 'Пешком'"
                                   [ngStyle]="{'fill': !checkpointPas.inspection ? 'white' : 'black' }"></use>
                              <use xlink:href="img/icons/icons.svg#by-truck-car"
                                   *ngIf="checkpointPas.type == 'Грузовой автомобиль'"
                                   [ngStyle]="{'fill': !checkpointPas.inspection ? 'white' : 'black' }"></use>
                              <use xlink:href="img/icons/icons.svg#by-car-main"
                                   *ngIf="checkpointPas.type == 'Легковой автомобиль'"
                                   [ngStyle]="{'fill': !checkpointPas.inspection ? 'white' : 'black' }"></use>
                            </svg>
                          </div>
                          <div class="security-page__line"
                               *ngIf="checkpointPas.type != 'Пешком' && checkpointPas.cargo == true"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">I
                          </div>
                          <div class="security-page__way-icon" *ngIf="checkpointPas.cargo == true">
                            <svg>
                              <use xlink:href="img/icons/icons.svg#mycar1with"></use>
                            </svg>
                          </div>
                          <div class="security-page__line"
                               *ngIf="checkpointPas.type != 'Пешком' && checkpointPas.carBrand != ''"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">I
                          </div>
                          <div class="security-page__way-text"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.carBrand}}</div>
                          <div class="security-page__line" *ngIf="checkpointPas.type != 'Пешком'"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">I
                          </div>
                          <div class="security-page__way-text"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">{{checkpointPas.governmentNumber}}</div>
                        </div>
                        <div class="security-page__departure w_14"
                             *ngIf="checkpointPas.status == 'Ожидается' && role != 'SecurityOfficer'">
                          <div class="security-page__arrive-photo security-page__arrive-round"
                               [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">

                          </div>
                          <div class=" security-page__departure-none"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            - -
                          </div>
                        </div>
                        <div class="security-page__arrive w_14"
                             *ngIf="checkpointPas.status == 'На территории' || checkpointPas.status == 'Вне территории' || checkpointPas.status == 'Пропуск сдан' || checkpointPas.status == 'Выдан пропуск'">
                          <div class="security-page__arrive-date"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            {{ choseDate(checkpointPas.arrivalDateDateTime, checkpointPas.arrivalDateDateTimeFireBird) | date:'HH:mm dd-MM-yyyy': 'UTC+3' }}
                          </div>
                        </div>
                        <div class="security-page__arrive w_14 js-security-page__arrive"
                             *ngIf="checkpointPas.status == 'Ожидается'  && role == 'SecurityOfficer'"
                             (click)="ArrivePopupDialog(checkpointPas)">
                          <div class="security-page__arrive-photo security-page__arrive-nophoto">
                          </div>
                          <div class=" security-page__guest-come"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            Гость приехал
                          </div>
                        </div>

                        <div class="security-page__arrive w_14"
                             *ngIf="checkpointPas.status == 'Вне территории' || checkpointPas.status == 'Пропуск сдан'">
                          <div class="security-page__arrive-date"
                               [ngStyle]="{'color': checkpointPas.inspection ? 'black' : 'white' }">
                            {{choseDate(checkpointPas.dateOfDepartureDateTime, checkpointPas.dateOfDepartureDateTimeFireBird) | date:'HH:mm dd-MM-yyyy': 'UTC+3' }}
                          </div>
                        </div>
                        <div class="security-page__departure w_14"
                             *ngIf="(checkpointPas.status == 'Ожидается' ) || (checkpointPas.status == 'На территории' || checkpointPas.status == 'Выдан пропуск') && role != 'SecurityOfficer'">
                          <div class="security-page__arrive-photo security-page__arrive-round"
                               [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">

                          </div>
                          <div class=" security-page__departure-none"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            - -
                          </div>
                        </div>
                        <div class="security-page__departure js-security-page__departure w_14"
                             *ngIf="!checkpointPas.dateOfDeparture && checkpointPas.arrivalDate && role == 'SecurityOfficer'"
                             (click)="DeparturePopupDialog(checkpointPas)">
                          <div class="security-page__arrive-photo security-page__arrive-arrive">

                          </div>
                          <div class="security-page__arrive-date security-page__arrive-nodate"
                               [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                            Гость уехал
                          </div>
                        </div>
                        <div w_9 class="status" [ngClass]="{
                                                        'security-page__status-expired' : checkpointPas.status == 'Просрочен',
                                                        'security-page__status' : checkpointPas.status == 'Вне территории' || checkpointPas.status == 'Пропуск сдан',
                                                        'security-page__status-waiting' : checkpointPas.status == 'Ожидается',
                                                        'security-page__status-come' : checkpointPas.status == 'На территории' || checkpointPas.status == 'Выдан пропуск'}">
                          {{checkpointPas.status}}
                        </div>
                        <div class="my-page__settings w_17">
                                                        <span *ngIf="role == 'SecurityOfficer'"
                                                              class="organization-name-span"
                                                              [ngStyle]="{'color': !checkpointPas.inspection ? 'white' : 'black' }">
                                                            {{checkpointPas.organizationName}}
                                                        </span>
                          <div class="my-page__settings-edit my-page__settings-round my-page__settings-round-edit"
                               *ngIf="checkpointPas.status == 'Ожидается' && role != 'SecurityOfficer' && ((checkpointPas.organizationUserCheckpointPassId == user.organizationId) || ( checkpointPas.userId == user.id) || (role == 'SeniorManager' || role == 'Manager' || role == 'Administrator' || role == 'SeniorSecurityOfficer'))"
                               (click)="EditPassPopupDialog(checkpointPas)"
                               [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <!--  *ngIf="!checkpointPas.arrivalDate" -->
                            <svg>
                              <use xlink:href="img/icons/icons.svg#security-page__settings-Edit"></use>
                            </svg>
                          </div>
                          <div class="my-page__settings-copy my-page__settings-round"
                               *ngIf="role != 'SecurityOfficer' && checkpointPas.positionInGroup != 0"
                               (click)="chooseDuplicatePassMethodComponent(checkpointPas.id, true)"
                               [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <svg>
                              <use xlink:href="img/icons/icons.svg#security-page__settings-copy"></use>
                            </svg>
                            <div class="my-page__settings-copy-block">Дублировать как новую</div>
                          </div>
                          <div class="my-page__settings-copy my-page__settings-round"
                               *ngIf="role != 'SecurityOfficer' && checkpointPas.positionInGroup == 0"
                               (click)="chooseDuplicatePassMethodComponent(checkpointPas.id, false)"
                               [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <!-- "duplicatePass(checkpointPas.id)" -->
                            <svg>
                              <use xlink:href="img/icons/icons.svg#security-page__settings-copy"></use>
                            </svg>
                            <div class="my-page__settings-copy-block">Дублировать как новую</div>
                          </div>
                          <div
                            class="my-page__settings-favourite js-my-page__settings-favourite my-page__settings-round my-page__settings-round-favour"
                            *ngIf="role != 'SecurityOfficer' && checkpointPas.chosen == false"
                            (click)="addPassToFavorite(checkpointPas.id)"
                            [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <svg>
                              <use xlink:href="img/icons/icons.svg#security-page__settings-favourite-hover"></use>
                            </svg>
                          </div>
                          <div
                            class="my-page__settings-favourite js-my-page__settings-favourite my-page__settings-round my-page__settings-round-favour"
                            *ngIf="role != 'SecurityOfficer' && checkpointPas.chosen == true"
                            (click)="removePassFromFavorite(checkpointPas.id)"
                            [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <svg style="    fill: #ffbc3a!important; stroke: #ffbc3a;">
                              <use xlink:href="img/icons/icons.svg#security-page__settings-favourite-hover"></use>
                            </svg>
                          </div>
                          <div
                            class="my-page__settings-favourite js-my-page__settings-favourite my-page__settings-round my-page__settings-round-favour"

                            [matMenuTriggerFor]="aboveMenu"
                            *ngIf="role != 'SecurityOfficer'"
                            [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <mat-icon style="color: #B5BDC0;">feed</mat-icon>
                          </div>
                          <div
                            class="my-page__settings-favourite js-my-page__settings-favourite my-page__settings-round my-page__settings-round-favour"
                            (click)="sendMailComponent(checkpointPas.userId)"
                            *ngIf="role == 'SecurityOfficer' || role == 'SeniorSecurityOfficer'"
                            [ngStyle]="{'background': !checkpointPas.inspection ? '#696969' : '#F8F8FA' }">
                            <mat-icon style="color: #B5BDC0;">feedback</mat-icon>
                          </div>
                          <mat-menu #aboveMenu="matMenu" yPosition="above">
                            <div class="security-page__arrive-details-wrapper" style="padding: 20px 40px 20px;">
                              {{checkpointPas.thePurposeOfTheVisit}}
                            </div>
                          </mat-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <!-- <div class="security-page__bottom">
            <div class="security-page__bottom-navigation">
                <div class="security-page__btn-arrow security-page__btn-arrow-preview">
                    <svg>
                        <use xlink:href="img/icons/icons.svg#arrow-nav-bott"></use>
                    </svg>
                </div>
                <div class="security-page__btn-location security-page__btn-location-active">
                    1
                </div>
                <div class="security-page__btn-location">
                    2
                </div>
                <div class="security-page__btn-location">
                    3
                </div>
                <div class="security-page__btn-location">
                    4
                </div>
                <div class="security-page__btn-arrow security-page__btn-arrow-next security-page__btn-arrow-active">
                    <svg>
                        <use xlink:href="img/icons/icons.svg#arrow-navogation"></use>
                    </svg>
                </div>
        </div>
    </div> -->
      </div>
    </div>
  </div>
</div>

import {HttpClient} from '@angular/common/http';
import {toBase64String} from '@angular/compiler/src/output/source_map';
import {Injectable} from '@angular/core';
import {response} from 'express';
import {AquaringSber} from 'src/app/models/aquaringSber.models';
import {Order} from 'src/app/models/order.models';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(protected http: HttpClient) {
    this.getAcuaringData();
  }

  public orders!: Order[];
  public ordersNotChanged!: Order[];

  public qrAquaringsData: AquaringSber[] = [];
  public qrAcquiring!: QrAcquiringResponse;

  public getQrAcquiring(amount: string, description: string) {
    let amountNumber = Number(amount);
    amountNumber = amountNumber * 100;
    this.http.get<QrAcquiringResponse>('/api/payment/QrAcquiring?amount=' + amountNumber.toString() + "&description=" + description).subscribe(response => {
      this.qrAcquiring = response;
    })
  }

  public getAcuaringData() {
    this.http.get<AquaringSber[]>('/api/AquaringSber').subscribe(response => {
      this.qrAquaringsData = response;
    });
  }

  public toTrueFormat(amount: string): string {
    var b = ".";
    var position = -2;
    var output = [amount.slice(0, position), b, amount.slice(position)].join('');
    return output;
  }
}

interface QrAcquiringResponse {
  orderId: string;
  formUrl: string;
}

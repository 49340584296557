<div class="card-user-popup js-card-user-popup" style="max-width: 90%!important;">
  <div class="card-user-popup__wrapper">
    <div class="card-user-popup__top">
      <div class="card-user-popup__person-info">
        <div class="card-user-popup__person-img-block">
          <img class=" images card-user-popup__person-img" src="{{data.user.avatar}}" alt="person">
        </div>
        <div class="card-user-popup__full-name">
          {{data.user.firstName}} {{data.user.lastName}} {{data.user.patronymic}}
        </div>
      </div>
      <div class="card-user-popup__other">
        <div class=" card-user-popup__round card-user-popup__edit js-card-user-popup__edit"
             *ngIf="role == 'SeniorSecurityOfficer' || role == 'Administrator' || role == 'SeniorManager'"
             (click)="CardEditPopupDialog()" mat-dialog-close>
          <img class="card-user-popup__edit-img" src="img/edit-popup.svg" alt="edit">
        </div>
        <div class="card-user-popup__round card-user-popup__close js-card-user-popup__close" mat-dialog-close>
          <img class="card-user-popup__close-img" src="img/close-popup.svg" alt="close">
        </div>
      </div>
    </div>
    <div class="card-user-popup__hr"></div>
    <div class="card-user-popup__info">
      <div class="card-user-popup__title">Контактные данные</div>
      <div class="card-user-popup__contacts">
        <div class="card-user-popup__contact">
          <div class="card-user-popup__icon-link">
            <img class="images card-user-popup__icon-link-img" src="img/hove-svg.svg" alt="home">
          </div>
          <div class="card-user-popup__contact-text">
            {{data.user.claimedRole}} в
            <a class="card-user-popup__contact-link-company">{{data.organizationName}}</a>
          </div>
        </div>
        <div class="card-user-popup__contact">
          <div class="card-user-popup__icon-link">
            <!-- <img class="images card-user-popup__icon-link-img" src="img/phone-svg.svg" alt="home"> -->
            <mat-icon [ngStyle]="{'color': data.user.phoneIsVerifed ? '#ea5c0a' : '#464646' }">call</mat-icon>
          </div>
          <div class="card-user-popup__contact-text">
            <a href="tel:+7{{data.user.phone}}" class="card-user-popup__contact-link">+7{{data.user.phone}}</a>
          </div>
        </div>
        <div class="card-user-popup__contact">
          <div class="card-user-popup__icon-link">
            <!-- <img class="images card-user-popup__icon-link-img" src="img/main-svg.svg" alt="home"> -->
            <mat-icon [ngStyle]="{'color': data.user.emailIsVerifed ? '#ea5c0a' : '#464646' }">mail_outline</mat-icon>
          </div>
          <div class="card-user-popup__contact-text">
            <a href="mailto:{{data.user.email}}" class="card-user-popup__contact-link">{{data.user.email}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-edit-popup js-card-edit-popup">
  <div class="card-edit-popup__wrapper">
    <div class="card-edit-popup__top">
      <div class="card-edit-popup__title">Настройки</div>
    </div>
    <div class="card-edit-popup__hr"></div>
    <div class="card-edit-popup__full-info">
      <div class="card-edit-popup__edit-photo">
        <img class="images card-edit-popup__photo" src="{{data.avatar}}" alt="person">

        <div (click)="cropImagePopupDialog()" class="card-edit-popup__chose-photo"
             style="
                background-color: rgb(43 43 43 / 43%) !important;
                border-radius: 50% !important;
                display: hidden !important;
                width: 50px !important;
                height: 50px !important; padding: 22%;"
             alt="chose">
          <mat-icon style="color:#fff">file_upload</mat-icon>
        </div>
      </div>
      <div class="card-edit-popup__infos">
        <label>
          <span class="card-edit-popup__label">Фамилия:</span>
          <input type="text" class="card-edit-popup__input" [(ngModel)]="userChange.lastName">
        </label>
        <label>
          <span class="card-edit-popup__label">Имя:</span>
          <input type="text" class="card-edit-popup__input" [(ngModel)]="userChange.firstName">
        </label>
        <label>
          <span class="card-edit-popup__label">Отчество</span>
          <input type="text" class="card-edit-popup__input" [(ngModel)]="userChange.patronymic">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__others-info">
      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <!-- <img class="images card-user-popup__icon-link-img" src="img/phone-svg.svg" alt="home"> -->
          <mat-icon [ngStyle]="{'color': data.phoneIsVerifed ? '#ea5c0a' : '#464646' }">call</mat-icon>
        </div>
        <label>
          <span class="card-edit-popup__label">Контактный телефон:</span>
          <input prefix="+7 " mask="(000) 000-00-00" [validation]="true" type="tel" class="card-edit-popup__input"
                 [(ngModel)]="userChange.phone">
        </label>

      </div>
      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <!-- <img class="images card-user-popup__icon-link-img" src="img/main-svg.svg" alt="home"> -->
          <mat-icon [ngStyle]="{'color': data.emailIsVerifed ? '#ea5c0a' : '#464646' }">mail_outline</mat-icon>
        </div>
        <label>
          <span class="card-edit-popup__label">Email:</span>
          <input type="email" class="card-edit-popup__input" [(ngModel)]="userChange.email">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__bottoms">
      <a class="card-edit-popup__delete-btn js-card-edit-popup__delete-btn" (click)="deleteMyAccount()"
         mat-dialog-close>
        <img class="card-edit-popup__basket-img" src="img/card-edit-popup__-basket.svg" alt="img">
        <span class="card-edit-popup__basket-span" mat-dialog-close>Удалить аккаунт</span>
      </a>
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-card-edit-popup__removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-card-edit-popup__conservation" (click)="changeMyAccount()"
                mat-dialog-close>Сохранить
        </button>
      </div>
    </div>
  </div>
</div>

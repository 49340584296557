import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HubConnectionState} from '@microsoft/signalr';
import {LocalStorageService} from './local-storage.service';
import {Roles} from './models/roles.moel';
import {SignalRService} from './signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SPKB';
  signalRConnected: boolean = true;
  logedId: boolean = false;
  loading = true;

  constructor(public localStorag: LocalStorageService, private router: Router, public http: HttpClient, private signalRService: SignalRService) {

    this.localStorag.set("SignalR", HubConnectionState.Disconnected);
    if (localStorage.getItem("role") == null) this.localStorag.set("role", Roles.Guest);
    if (localStorage.getItem("logedIn") == null) this.localStorag.set("logedIn", false);
    this.updateLogedInStatus();
    var logedIn: Boolean = this.localStorag.get("logedIn");
    if (logedIn == true) {
      this.signalRService.connect();
    }
  }

  ngOnInit() {
    var logedIn: Boolean = this.localStorag.get("logedIn");
    this.signalRService.onconnected.subscribe(() => {
      this.loading = false;
    });
    this.signalRService.onconnected.subscribe(() => {
      this.updateLogedInStatus();
      if (logedIn)
        this.loading = false;
    });
    this.signalRService.connection.onclose(() => {
      this.updateLogedInStatus();
      if (logedIn)
        this.loading = true;
    });
    this.signalRService.connection.onreconnecting(() => {
      this.updateLogedInStatus();
      if (logedIn)
        this.loading = true;
    });
    this.signalRService.connection.onreconnected(() => {
      this.updateLogedInStatus();
      if (logedIn)
        this.loading = false;
    });
  }

  updateLogedInStatus() {
    this.logedId = this.localStorag.get("logedIn");
  }
}

import '@angular/compiler';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormsModule} from "@angular/forms";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CropImageUserPopupDialog, HeaderComponent, UserEditPopupDialog} from './header/header.component';
import {
  AddPassPopupDialog,
  ArrivePopupDialog,
  DeparturePopupDialog,
  EditPassPopupDialog,
  MyPassPageComponent
} from './my-pass-page/my-pass-page.component';
import {
  AddOrganizationPopupDialog,
  CardEditPopupDialog,
  CardUserPopupDialog,
  CropImageOrganizationPopupDialog,
  CropImagePopupDialog,
  DialogContentExampleDialog,
  EditOrganizationPopupDialog,
  UsersListComponent
} from './users-list/users-list.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {EntranceComponent} from './entrance/entrance.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {EntranceSmsComponent} from './entrance-sms/entrance-sms.component';
import {EntranceNewPasswordComponent} from './entrance-new-password/entrance-new-password.component';
import {TokenInterceptor} from './auth/token.interceptor';
import {WaitingForSubmitComponent} from './waiting-for-submit/waiting-for-submit.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CdkAccordionModule} from '@angular/cdk/accordion';

import {NgxCurrencyModule} from "ngx-currency";

/** Import the component modules */
import {LyToolbarModule} from '@alyle/ui/toolbar';
import {ClipboardModule} from '@angular/cdk/clipboard';

/** Import themes */
import {MinimaLight, MinimaDark} from '@alyle/ui/themes/minima';
import {OverlayModule} from "@angular/cdk/overlay";

import {
  HAMMER_GESTURE_CONFIG,
  HammerModule
} from '@angular/platform-browser';
import {ReportComponent} from './report/report.component';
import {DocumentationComponent} from './documentation/documentation.component';
import {ApiComponent} from './api/api.component';
import {AddPassDirectorPopupComponent} from './add-pass-director-popup/add-pass-director-popup.component';
import {CreateIntegrationKeyComponent} from './create-integration-key/create-integration-key.component';
import {NgxMaskModule, IConfig} from 'ngx-mask'
import {CountdownGlobalConfig, CountdownModule} from 'ngx-countdown';

import {LyImageCropperModule} from '@alyle/ui/image-cropper';
import {LyButtonModule} from '@alyle/ui/button';
import {LyIconModule} from '@alyle/ui/icon';

import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig
} from '@alyle/ui';
import {ApplicationSettingsComponent} from './application-settings/application-settings.component';
import {EmailConfirmationComponent} from './email-confirmation/email-confirmation.component';
import {SendMailComponent} from './send-mail/send-mail.component';
import {AlertsComponent} from './alerts/alerts.component';
import {SendMailWithChooseUserComponent} from './send-mail-with-choose-user/send-mail-with-choose-user.component';
import {ImportExportComponent} from './import-export/import-export.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {
  ChooseDuplicatePassMethodComponent
} from './choose-duplicate-pass-method/choose-duplicate-pass-method.component';

import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {PwaInstallIosComponent} from './pwa-install-ios/pwa-install-ios.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConnectingSignalRComponent} from './errors/connecting-signal-r/connecting-signal-r.component';
import {PaymentQrComponent} from './finance/payment-qr/payment-qr.component';
import {AddQrCodeComponent} from './finance/add-qr-code/add-qr-code.component';
import {OrderComponent} from './order/order/order.component';
import {OrdersComponent} from './order/orders/orders.component';
import {ProductsComponent} from './product/products/products.component';
import {AddProductComponent} from './product/add-product/add-product.component';
import {ProductComponent} from './product/product/product.component';
import {
  NewOrderWithoutRegistrationComponent
} from './order/new-order-without-registration/new-order-without-registration.component';
import {NewPaymentComponent} from './order/new-payment/new-payment.component';
import {PayComponent} from './order/pay/pay.component';
import {OrderAquaringsComponent} from './order/order-aquarings/order-aquarings.component';
import {AddAquaringComponent} from './order/add-aquaring/add-aquaring.component';
import {ConditionsComponent} from './order/conditions/conditions.component';
import {ConditionsPageComponent} from './order/conditions-page/conditions-page.component';
import {ShopUserDialogDialog, ShopUsersComponent} from './finance/shop-users/shop-users.component';
import {IMaskModule} from "angular-imask";
//import { AddGuestComponent } from './finance/shop-users/add-guest/add-guest.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MyPassPageComponent,
    UsersListComponent,
    AddOrganizationPopupDialog,
    DialogContentExampleDialog,
    CardEditPopupDialog,
    EntranceComponent,
    PageNotFoundComponent,
    EntranceSmsComponent,
    EntranceNewPasswordComponent,
    AddPassPopupDialog,
    UserEditPopupDialog,
    ArrivePopupDialog,
    DeparturePopupDialog,
    CardUserPopupDialog,
    EditPassPopupDialog,
    EditOrganizationPopupDialog,
    WaitingForSubmitComponent,
    CropImagePopupDialog,
    CropImageUserPopupDialog,
    CropImageOrganizationPopupDialog,
    ReportComponent,
    DocumentationComponent,
    ApiComponent,
    AddPassDirectorPopupComponent,
    CreateIntegrationKeyComponent,
    ApplicationSettingsComponent,
    EmailConfirmationComponent,
    SendMailComponent,
    AlertsComponent,
    SendMailWithChooseUserComponent,
    ImportExportComponent,
    ScheduleComponent,
    ChooseDuplicatePassMethodComponent,
    PwaInstallIosComponent,
    ConnectingSignalRComponent,
    PaymentQrComponent,
    AddQrCodeComponent,
    OrderComponent,
    OrdersComponent,
    ProductsComponent,
    AddProductComponent,
    ProductComponent,
    NewOrderWithoutRegistrationComponent,
    NewPaymentComponent,
    PayComponent,
    // DeviceDetectorService,
    OrderAquaringsComponent,
    AddAquaringComponent,
    ConditionsComponent,
    ConditionsPageComponent,
    ShopUsersComponent,
    ShopUserDialogDialog,
    //AddGuestComponent

  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CdkAccordionModule,
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,
    HammerModule,
    ClipboardModule,
    NgxMaskModule.forRoot({
      validation: false
    }),
    CountdownModule,
    LyIconModule,
    OverlayModule,
    // DeviceDetectorService,
    NgxMatTimepickerModule.setLocale('ru-RU'),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxCurrencyModule,
    IMaskModule
  ],
  exports: [
    MatSliderModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRippleModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    [LyTheme2],
    [StyleRenderer],
    // Theme that will be applied to this module
    {provide: LY_THEME_NAME, useValue: 'minima-light'},
    {provide: LY_THEME, useClass: MinimaLight, multi: true}, // name: `minima-light`
    {provide: LY_THEME, useClass: MinimaDark, multi: true}, // name: `minima-dark`
    // Gestures
    {provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}},
    {
      provide: DeviceDetectorService,
      // useClass: UniversalDeviceDetectorService
    },
    AppComponent,
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}

// "build": "ng build --environment=prod && tar -zcvf archive.tar.gz dist/prod/*",

import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from '../local-storage.service';
import {User, UserForRegistration, UserPublicData} from '../models/user.model';
import {Token} from '../models/token.model';
import {AppComponent} from '../app.component';
import {SignalRService} from '../signal-r.service';
import {Roles, RolesForRegistration} from '../models/roles.moel';
import {Organization, OrganizationForRegistration} from '../models/organization.model';
import {MatDialog} from '@angular/material/dialog/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-entrance',
  templateUrl: './entrance.component.html',
  styleUrls: ['./entrance.component.css']
})
export class EntranceComponent implements OnInit {
  token!: Token;
  user!: UserPublicData;
  phonevar!: string;
  organizationdNames!: OrganizationForRegistration[];
  myModel: any;
  activateRegButton: boolean = true;
  activateRemindButton: boolean = true;
  activateLoginButton: boolean = true;
  public promptEvent: any;

  roles: string[] = [];
  public userReg: UserForRegistration = {
    claimedRole: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
    password: "",
    organizationId: 0,
  };
  public mask2 = [['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]]

  constructor(public localStorag: LocalStorageService, private router: Router, public http: HttpClient, private appComponent: AppComponent, private signalRService: SignalRService, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.roles = Object.keys(RolesForRegistration);
    this.OrganizationsNamesArray();
  }

  public Login() {
    this.appComponent.loading = true;
    this.http.get<Token>("/Account/token?login=" + this.userReg.phone + "&Password=" + this.userReg.password, {}).subscribe((result) => {
      this.token = result;
      this.localStorag.set("role", this.token.role);
      this.localStorag.set("access_token", this.token.access_token);
      this.localStorag.set("email", this.token.email);
      this.localStorag.set("username", this.token.username);
      this.localStorag.set("logedIn", true);
      this.http.get<UserPublicData>("/Account/DataAboutMyAccount").subscribe((result) => {
        this.localStorag.set("user", result);
        this.signalRService.connect();
        this.user = result;
        this.appComponent.logedId = true;
        if (!this.user.emailIsVerifed) {
          this._snackBar.open('Что б получать уведомления, подтвердите электронную почту');
        }
        if (result.phoneIsVerifed == false) {
          this.http.get("/Account/ResendConfirmSMS", {}).subscribe((result) => {
            this.localStorag.set("callAuth", false);
          }, error => {
            this.localStorag.set("callAuth", true);
            this.AuthCall();
          });
          localStorage.setItem("phone", this.user.phone);
          localStorage.setItem("sms", "verefication");
          this.router.navigate(['/entrance-sms']);
        } else if (result.role == Roles.Candidate) this.router.navigate(['/waiting-for-submit']);
        else this.router.navigate(['/']);

        this.http.get<boolean>('/Account/CurrentUserNeedChangePassword').subscribe((response) => {
          if (response) {
            this.router.navigate(["/entrance-new-password"]);
          }
        });
        // if(this.localStorag.get("FirstLoad") == true){
        //   this.localStorag.set("FirstLoad", false);
        //   window.location.reload();
        // }
        this.appComponent.loading = false;
      });
    }, error => {
      this.appComponent.loading = false;
      this.userReg.password = "";
    });
  }

  public Register(): void {
    this.appComponent.loading = true;
    this.http.post('/Account/Register', this.userReg, {}).subscribe(result => {
      this.Login();
    }, error => this.appComponent.loading = false);
  }

  public checkValidInput() {
    if (
      this.userReg.claimedRole == "" ||
      this.userReg.firstName == "" ||
      this.userReg.lastName == "" ||
      this.userReg.patronymic == "" ||
      this.userReg.phone.length != 10 ||
      this.userReg.email == "" ||
      this.userReg.password == ""
      // organizationId: 0
    ) {
      this.activateRegButton = true;
    } else {
      this.activateRegButton = false;
    }
  }

  public checkValidInputRemind() {
    if (
      this.userReg.phone.length != 10
    ) {
      this.activateRemindButton = true;
    } else {
      this.activateRemindButton = false;
    }
  }

  public checkValidInputLogin() {
    if (
      this.userReg.phone.length != 10 || this.userReg.password == ""
    ) {
      this.activateLoginButton = true;
    } else {
      this.activateLoginButton = false;
    }
  }

  public async SendLoginSMS() {
    this.appComponent.loading = true;
    this.http.get<any>('/Account/SendLoginSMS?phone=' + this.userReg.phone).subscribe(result => {
      this.appComponent.loading = false;
    }, error => {
      this.appComponent.loading = false;
      this.localStorag.set("phone", this.userReg.phone);
      this.localStorag.set("sms", "login");
      this.router.navigate(['/entrance-sms']);
      this.localStorag.set("callAuth", true);
    });
  }

  public OrganizationsNamesArray() {
    this.http.get<OrganizationForRegistration[]>('/Organization/GetAllOrganizationsNameForReg').subscribe((result) => {
      this.organizationdNames = result;
    });
  }

  private AuthCall() {
    console.log("AuthCall", this.user.phone);
    this.http.get("/Account/ReCallConfirm ? phone =" + this.user.phone).subscribe(() => {
      console.log('Make call to: ', this.user.phone);
    });
  }
}

import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {OrderService} from '../service/order.service';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.css']
})
export class NewPaymentComponent extends OrderService implements OnInit {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public linkForPay: string = "";

  ngOnInit(): void {
  }

  public generateLink(amount: string, description: string) {
    this.linkForPay = "https://spkbkpp.ru/pay?amount=" + amount + "&description=" + description;
  }
}

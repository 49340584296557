import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../local-storage.service';
import {FormGroup, FormControl, FormBuilder} from '@angular/forms';
import {CheckpointPass} from '../models/checkpointPass.model';
import {CheckpointPassCreate} from '../models/checkpointPassCreate.model';
import {PassengerCreate} from '../models/passengerCreate.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SignalRService} from '../signal-r.service';
import {Organization} from '../models/organization.model';
import {AddPassDirectorPopupComponent} from '../add-pass-director-popup/add-pass-director-popup.component';
import {User} from '../models/user.model';
import {Roles} from '../models/roles.moel';
import {IntegrationKeys} from '../models/integrationKeys.model';
import {SendMailComponent} from '../send-mail/send-mail.component';
import * as _ from "lodash";
import {
  ChooseDuplicatePassMethodComponent
} from '../choose-duplicate-pass-method/choose-duplicate-pass-method.component';
import {HubConnectionState} from '@microsoft/signalr';
import {AppComponent} from '../app.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-my-pass-page',
  templateUrl: './my-pass-page.component.html',
  styleUrls: ['./my-pass-page.component.css']
})
export class MyPassPageComponent implements OnInit {
  public checkpointPass: CheckpointPass[] = [];
  public checkpointPassFiltered: CheckpointPass[] = [];
  private favoriteCheckpointPasses: CheckpointPass[] = [];
  public tabs: Tabs[] = [];
  public role: string | null = "";
  public id!: string;
  public nowDate: Date = new Date();
  deviceInfo!: any;
  isMobile: boolean = false;
  public user!: User;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  from!: string;
  to!: string;
  control = new FormControl();
  // streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets!: Observable<CheckpointPass[]>;

  constructor(public localStorag: LocalStorageService, private router: Router, public http: HttpClient, public dialog: MatDialog, private route: ActivatedRoute,
              public signalr: SignalRService, private appComponent: AppComponent, private _snackBar: MatSnackBar
  ) {
    this.user = this.localStorag.get("user");
    if (this.user.role == Roles.Candidate) this.router.navigate(['/waiting-for-submit']);
    // var logedIn:Boolean = this.localStorag.get("logedIn");
    // if(logedIn == true){
    //   this.signalr.connect();
    // }
  }

  private subscribeOnSignalR() {
    this.signalr.connection.onreconnected((callback) => {
      this.updateData();
    })
    this.signalr.connection.on("CheckpointPassGet", async (checkpointPassResived: CheckpointPass) => await this.resivedNewCheckpointPass(checkpointPassResived).then());
    this.signalr.connection.on("FavoriteCheckpointPassGet", async (checkpointPassResived: CheckpointPass) => await this.resivedFavoriteCheckpointPass(checkpointPassResived).then());
  }

  private async resivedNewCheckpointPass(checkpointPassResived: CheckpointPass) {
    console.log(checkpointPassResived);
    this.http.get<CheckpointPass[]>('/CheckpointPass/GetPass', {}).subscribe((result) => {
      this.update(result);
    });

    /*let checkpointPas = { pass: this.checkpointPass.find(x => x.id == checkpointPassResived.id), index: this.checkpointPass.findIndex(x => x.id == checkpointPassResived.id) };
    if(checkpointPas.pass != undefined){
      this.checkpointPass[checkpointPas.index] = checkpointPassResived;
    }
    else{
      this.checkpointPass.unshift(checkpointPassResived);
    }
    this.update(this.checkpointPass);
     */
  }

  private async resivedFavoriteCheckpointPass(checkpointPassResived: CheckpointPass) {
    if (checkpointPassResived.chosen) {
      this.tabs[1].checkpointPass.unshift(checkpointPassResived);
    } else {
      var index = this.tabs[1].checkpointPass.findIndex(x => x.id == checkpointPassResived.id);
      this.tabs[1].checkpointPass.splice(index, 1);
    }
    // this.tabs[1].checkpointPass = this.tabs[1].checkpointPass.filter(x => x.chosen == true);
    this.tabs[1].name = "Избранные " + "(" + this.tabs[1].checkpointPass.filter(x => x.chosen == true).length.toString() + ")";
  }

  private _filter(value: string): CheckpointPass[] {
    const filterValue = this._normalizeValue(value);
    var result = this.checkpointPass.filter(x => this._normalizeValue(x.organizationName).includes(filterValue)
      || this._normalizeValue(x.firstName).includes(filterValue)
      || this._normalizeValue(x.lastName).includes(filterValue)
      || this._normalizeValue(x.patronymic).includes(filterValue)
      || this._normalizeValue(x.thePurposeOfTheVisit).includes(filterValue)
      || this._normalizeValue(x.governmentNumber.toString()).includes(filterValue)
      || this._normalizeValue(x.document.toString()).includes(filterValue));
    this.update(result);
    return result;
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public isNowday(arrivalFrom: Date): boolean
  {
    let date = new Date();
    let arrivalFromDate = new Date(arrivalFrom);

    date.setHours(0,0,0,0);
    arrivalFromDate.setHours(0,0,0,0);

    return date.getTime() === arrivalFromDate.getTime();
  }
  public dateRangee(from: string, to: string) {
    this.from = from;
    this.to = to;
    this.http.get<CheckpointPass[]>('/CheckpointPass/GetPassByDateRange?from=' + from + "&to=" + to, {}).subscribe((result) => {
      this.checkpointPass = result;
      // this.tabs[0].checkpointPass = result;
      this.update(result);
    });
  }

  private update(checkpointPass: CheckpointPass[]) {
    this.tabs[0].checkpointPass = checkpointPass;
    this.tabs[2].checkpointPass = checkpointPass.filter(x => x.status == "Ожидается");
    this.tabs[3].checkpointPass = checkpointPass.filter(x => x.status == "На территории");
    this.tabs[4].checkpointPass = checkpointPass.filter(x => x.status == "Вне территории");
    this.tabs[5].checkpointPass = checkpointPass.filter(x => x.status == "Просрочен");
    this.tabs[6].checkpointPass = checkpointPass.filter(x => x.cargo == true);
  }

  ngOnInit() {
    this.signalr.onconnected.subscribe(() => {
      if (this.localStorag.get("SignalR") == HubConnectionState.Connected) {
        this.subscribeOnSignalR();
      }
    });
    this.id = this.route.snapshot.params['id'];
    var role = localStorage.getItem("role");
    if (role != null) {
      this.role = role.substring(0, role.length - 1);
      this.role = this.role.substring(1);
    }

    this.updateData();
    // this.signalr.connection.onclose = () => {
    //   this.signalr.connection.off("CheckpointPassGet");
    // }
  }

  private updateData(): void {
    if (this.id != null) {
      this.http.get<CheckpointPass[]>('/CheckpointPass/GetPassByUserId?id=' + this.id, {}).subscribe((result) => {
        this.checkpointPass = result;
        this.tabsSep(result);
      }, error => {
        if (this.localStorag.get("SignalR") == HubConnectionState.Connected) this.appComponent.loading = false;
      });
    } else {
      this.http.get<CheckpointPass[]>('/CheckpointPass/GetPass', {}).subscribe((result) => {
        this.checkpointPass = result;
        this.tabsSep(result);
      }, error => {
        if (this.localStorag.get("SignalR") == HubConnectionState.Connected) this.appComponent.loading = false;
      });
    }
    this.http.get<CheckpointPass[]>('/CheckpointPass/Favorites').subscribe((favoriteCheckpointPasses) => {
      this.favoriteCheckpointPasses = favoriteCheckpointPasses;
      console.log(favoriteCheckpointPasses);
      this.tabs[1].checkpointPass = favoriteCheckpointPasses;
      this.tabs[1].name = "Избранные " + "(" + this.favoriteCheckpointPasses.filter(x => x.chosen == true).length.toString() + ")";
    });
  }
  private tabsSep(checkpointPass: CheckpointPass[]) {
    this.tabs.push({
      name: "Все",
      checkpointPass: checkpointPass
    });
    this.tabs.push({
      name: "Избранные " + "(" + this.favoriteCheckpointPasses.filter(x => x.chosen == true).length.toString() + ")",
      checkpointPass: this.favoriteCheckpointPasses.filter(x => x.chosen == true)
    });
    this.tabs.push({
      name: "Ожидается",
      checkpointPass: checkpointPass.filter(x => x.status == "Ожидается")
    });
    this.tabs.push({
      name: "На территории",
      checkpointPass: checkpointPass.filter(x => x.status == "На территории")
    });
    this.tabs.push({
      name: "Вне территории",
      checkpointPass: checkpointPass.filter(x => x.status == "Вне территории")
    });
    this.tabs.push({
      name: "Просрочен",
      checkpointPass: checkpointPass.filter(x => x.status == "Просрочен")
    });
    this.tabs.push({
      name: "С грузом",
      checkpointPass: checkpointPass.filter(x => x.cargo == true)
    });

    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  aloneOrNot(checkpointPasses: CheckpointPass[], groupNumber: string): number | null {
    var i = 0;
    var count = checkpointPasses.filter(x => x.groupNumber == groupNumber).length;
    checkpointPasses.forEach(x => {
      if (count > 1 && x.groupNumber == groupNumber) {
        if (i == 0) {
          x.positionInGroup = 1;
        } else if (i + 1 == count) {
          x.positionInGroup = 3;
        } else {
          x.positionInGroup = 2;
        }
        i++;
      }
      ;
    });
    return count;
  }

  chooseDuplicatePassMethodComponent(id: number, isGroup: boolean): void {
    var addPassPopupData: AddPassPopupData = {
      isGroup: isGroup,
      passIdForDuplicate: id
    }
    const dialogRef = this.dialog.open(ChooseDuplicatePassMethodComponent, {
      data: addPassPopupData,
    });
  }

  addPassToFavorite(id: number) {
    this.http.get<any>('/CheckpointPass/AddToFavorite?id=' + id, {}).subscribe();
  }

  removePassFromFavorite(id: number) {
    this.http.get<any>('/CheckpointPass/RemoveFromFavorite?id=' + id, {}).subscribe();
  }

  addPassPopup() {
    var addPassPopupData: AddPassPopupData = {
      isGroup: false,
      passIdForDuplicate: null
    }
    const dialogRef = this.dialog.open(AddPassPopupDialog, {
      data: addPassPopupData
    });
  }

  sendMailComponent(id: number): void {
    this.dialog.open(SendMailComponent, {
      data: id
    });
  }

  addPassDirectorPopup() {
    const dialogRef = this.dialog.open(AddPassDirectorPopupComponent);
  }

  ArrivePopupDialog(checkpointPass: CheckpointPass) {
    const dialogRef = this.dialog.open(ArrivePopupDialog, {
      data: checkpointPass
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DeparturePopupDialog(checkpointPass: CheckpointPass) {
    const dialogRef = this.dialog.open(DeparturePopupDialog, {
      data: checkpointPass
    });
  }

  EditPassPopupDialog(checkpointPass: CheckpointPass) {
    const dialogRef = this.dialog.open(EditPassPopupDialog, {
      data: checkpointPass
    });
  }

  public duplicatePass(id: number): void {
    this.http.get<any>('/CheckpointPass/DuplicatePass?id=' + id + '&isGroup=false', {}).subscribe(() => {
      this._snackBar.open("Пропуск дублирован", "Скрыть");
    });
  }

  isInRange(arrivalFrom: Date): boolean {
    let date = new Date();
    let arrivalFromDate = new Date(arrivalFrom);

    date.setHours(0,0,0,0);
    arrivalFromDate.setHours(0,0,0,0);

    return date > arrivalFromDate;
  }
  choseDate(value: Date | null, valueFireBird: Date | null): Date | null {
    return value ? value : valueFireBird;
  }
}


interface AddPassPopupData {
  passIdForDuplicate: number | null;
  isGroup: boolean;
}

@Component({
  selector: 'add-pass-popup',
  templateUrl: 'add-pass-popup.html',
})
export class AddPassPopupDialog implements OnInit {
  range = new FormGroup({});

  public customPatterns = {
    'V': {pattern: new RegExp('\[a-zA-Zа-яА-Я\]')},
    'B': {pattern: new RegExp('\[А-Я0-9\]')},
    'L': {pattern: new RegExp('\[0-9\]')},
  };
  public myControl = new FormControl();
  public filteredOptions!: Observable<string[]>;
  public checkpointPass!: CheckpointPassCreate;
  public empPassenger!: PassengerCreate;
  public passengers: PassengerCreate[] = [];
  public cars: string[] = ["Kia", "Lada", "Daewoo", "Bugatti", "Toyota", "Lexus", "Audi", "Volkswagen", "Mercedes", "Maserati", "Ford", "Ferrari", "Lamborghini", "Tesla", "Porsche",
    "BMW", "Bentley", "Mazda", "Cherry", "Renault", "Dacia", "Nissan", "Volvo", "Citroen", "Subaru", "Mitsubishi", "Peugeot", "Jeep", "Hyundai", "Honda", "Jaguar", "Suzuki", "Skoda", "ВАЗ", "ГАЗ", "ЗАЗ", "КрАЗ",
    "УАЗ"];
  public purposesOfTheVisit: string[] = ["Курьер", "Доставка еды", "Клиенты для просмотра товара", "Поставщик везет продукцию"];
  addButtonDisabled: boolean = true;
  warningGosNumber = false;
  public gosShortNumber = "";
  public trySendRequest = false;
  public withoutMask = false;

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: AddPassPopupData, private _fb: FormBuilder) {

  }

  ngOnInit(): void {
    let start = new Date();
    let end = new Date();
    end.setDate(end.getDate() + 1);
    this.range = this._fb.group({
      start: new FormControl(start),
      end: new FormControl(end),
    })
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    var empPassenger: PassengerCreate = {
      id: 0,
      userName: "",
      document: "",
      documentType: "Паспорт"
    };
    this.passengers.push(empPassenger);
    var empCheckpointPass: CheckpointPassCreate = {
      passengers: this.passengers,
      thePurposeOfTheVisit: "",
      type: "Пешком",
      cargo: false,
      governmentNumber: "",
      carBrand: "",
      arrivalFrom: this.range.controls.start.value,
      arrivalTo: this.range.controls.end.value,
    };
    if (this.data.passIdForDuplicate == null) {
      this.checkpointPass = empCheckpointPass;
    } else {
      this.http.get<CheckpointPass[]>("/CheckpointPass/DuplicatePassGetData?id=" + this.data.passIdForDuplicate + "&isGroup=" + this.data.isGroup).subscribe((checkpointPasses) => {
        var empPassengers: PassengerCreate[] = [];
        var i = 0;
        checkpointPasses.forEach(checkpointPass => {
          var empPassenger: PassengerCreate = {
            id: i,
            userName: checkpointPass.lastName + " " + checkpointPass.firstName + " " + checkpointPass.patronymic,
            document: checkpointPass.document,
            documentType: checkpointPass.documentType
          };
          i++;
          empPassengers.push(empPassenger);
        });
        var governmentNumber = "";
        if (checkpointPasses[0].governmentNumber.length <= 3) {
          this.gosShortNumber = checkpointPasses[0].governmentNumber;
        } else {
          governmentNumber = checkpointPasses[0].governmentNumber;
        }
        var empCheckpointPass: CheckpointPassCreate = {
          passengers: empPassengers,
          thePurposeOfTheVisit: checkpointPasses[0].thePurposeOfTheVisit,
          type: checkpointPasses[0].type,
          cargo: checkpointPasses[0].cargo,
          governmentNumber: governmentNumber,
          carBrand: checkpointPasses[0].carBrand,
          arrivalFrom: this.range.controls.start.value,
          arrivalTo: this.range.controls.end.value,
        };
        this.checkpointPass = empCheckpointPass;
        this.checkValidInput();
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.cars.filter(car => car.toLowerCase().indexOf(filterValue) === 0);
  }

  public createPass(): void {
    this.trySendRequest = true;
    this.checkpointPass.arrivalFrom = new Date(Date.UTC(this.range.controls.start.value.getFullYear(), this.range.controls.start.value.getMonth(), this.range.controls.start.value.getDate(), 0, 0, 0));
    this.checkpointPass.arrivalTo = new Date(Date.UTC(this.range.controls.end.value.getFullYear(), this.range.controls.end.value.getMonth(), this.range.controls.end.value.getDate(), 0, 0, 0));
    // this.checkpointPass.arrivalDateDateTime = this.range.controls.end.value;
    if (this.checkpointPass.governmentNumber.length == 0) this.checkpointPass.governmentNumber = this.gosShortNumber;
    this.http.post<CheckpointPassCreate>("/CheckpointPass/CreatePass", this.checkpointPass, {}).subscribe((result) => {

    });
  }

  public checkForPattern(checkpointPass: CheckpointPassCreate): boolean {
    console.log("checkForPattern");
    return checkpointPass.governmentNumber.match(new RegExp('\[a-zA-Z\]')) != null;
  }

  public checkValidInput() {
    this.checkForPattern(this.checkpointPass);
    this.checkpointPass.governmentNumber = this.checkpointPass.governmentNumber.toUpperCase();
    if (
      ((((this.checkpointPass.governmentNumber.length < 8 && !this.withoutMask) || (this.checkpointPass.governmentNumber.length > 14 && this.withoutMask)) && this.checkpointPass.type != "Пешком")
        || (this.checkpointPass.governmentNumber.match(new RegExp('\[a-zA-Z\]')) != null && this.checkpointPass.type != "Пешком")
        || (this.gosShortNumber.length < 3 && this.gosShortNumber.length != 0 && this.checkpointPass.type != "Пешком")
        || (this.gosShortNumber.length == 0 && this.checkpointPass.governmentNumber.length == 0 && this.checkpointPass.type != "Пешком")
        || this.checkpointPass.thePurposeOfTheVisit == "" || this.checkpointPass.passengers.filter(
          x => x.userName == ""
          //|| (x.document.length != 10 && x.documentType == "Водительское удостоверение")
          //|| (x.document.length != 9 && x.documentType == "Биометрический")
          //|| (x.document.length != 11 && x.documentType == "Паспорт")
        ).length != 0)
      // organizationId: 0
    ) {
      this.addButtonDisabled = true;
    } else {
      this.addButtonDisabled = false;
    }
    if (this.checkpointPass.governmentNumber.length != 8 && this.checkpointPass.governmentNumber.length != 9) {
      this.warningGosNumber = true;
    } else {
      this.warningGosNumber = false;
    }
  }

  public addGuest(): void {
    if (this.checkpointPass.passengers.length < 4) {
      var empPassenger: PassengerCreate = {
        id: this.checkpointPass.passengers[this.checkpointPass.passengers.length - 1].id + 1,
        userName: "",
        document: "",
        documentType: "Паспорт"
      };
      this.checkpointPass.passengers.push(empPassenger);
    }
    this.checkValidInput();
  }

  public deleteGuest(id: number): void {
    if (this.checkpointPass.passengers.length > 1) {
      for (var i = 0; i < this.checkpointPass.passengers.length; i++) {
        if (this.checkpointPass.passengers[i].id == id) {
          this.checkpointPass.passengers.splice(i, 1);
        }
      }
    }
    this.checkValidInput();
  }
}

@Component({
  selector: 'arrive-popup',
  templateUrl: 'arrive-popup.html',
})
export class ArrivePopupDialog implements OnInit{
  public now: Date = new Date();
  public form!: FormGroup;
  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: CheckpointPass, private _fb: FormBuilder) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnInit(): void {
    this.form = this._fb.group({
      type: new FormControl(this.data.type)
    });

    this.form.valueChanges.subscribe((response)=>{
      this.update();
      console.log(response)
    });
  }

  public update(): void {
    this.data.type = this.form.controls.type.value;
    this.http.post('/CheckpointPass/ChangePass', this.data).subscribe();
  }

  public arrive(rfId: string): void {
    this.http.get<any>('/CheckpointPass/Arrive?id=' + this.data.id + '&rfId=' + rfId, {}).subscribe();
  }

}

@Component({
  selector: 'departure-popup',
  templateUrl: 'departure-popup.html',
})
export class DeparturePopupDialog {
  public now: Date = new Date();

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: CheckpointPass) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  public checked: boolean = false;
  public disabled = false;

  public departure(): void {
    this.http.get<any>('/CheckpointPass/Departured?id=' + this.data.id + '&rfId=' + this.data.rfId, {}).subscribe();
  }
}

@Component({
  selector: 'edit-pass-popup',
  templateUrl: 'edit-pass-popup.html',
})
export class EditPassPopupDialog {
  range = new FormGroup({
    start: new FormControl(this.data.arrivalFrom),
    end: new FormControl(this.data.arrivalTo),
  });
  public purposesOfTheVisit: string[] = ["Курьер", "Доставка еды", "Клиенты для просмотра товара", "Поставщик везет продукцию"];

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: CheckpointPass, private _fb: FormBuilder) {
  }

  warningGosNumber = false;
  public gosShortNumber = "";
  public cars: string[] = ["Kia", "Lada", "Daewoo", "Bugatti", "Toyota", "Lexus", "Audi", "Volkswagen", "Mercedes", "Maserati", "Ford", "Ferrari", "Lamborghini", "Tesla", "Porsche",
    "BMW", "Bentley", "Mazda", "Cherry", "Renault", "Dacia", "Nissan", "Volvo", "Citroen", "Subaru", "Mitsubishi", "Peugeot", "Jeep", "Hyundai", "Honda", "Jaguar", "Suzuki", "Skoda", "ВАЗ", "ГАЗ", "ЗАЗ", "КрАЗ",
    "УАЗ"];
  public filteredOptions!: Observable<string[]>;
  public myControl = new FormControl();
  public customPatterns = {
    'V': {pattern: new RegExp('\[а-яА-Я\]')},
    'B': {pattern: new RegExp('\[а-яА-Я0-9\]')},
    'L': {pattern: new RegExp('\[0-9\]')},
  };

  ngOnInt() {
    console.log('EditPassPopupDialog', this.data);
    this.range = this._fb.group({
      start: new FormControl(this.data.arrivalFrom),
      end: new FormControl(this.data.arrivalTo),
    })
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.cars.filter(car => car.toLowerCase().indexOf(filterValue) === 0);
  }

  changePass() {
    if (this.data.governmentNumber.length == 0) this.data.governmentNumber = this.gosShortNumber;
    this.data.arrivalFrom = new Date(Date.UTC(this.range.controls.start.value.getFullYear(), this.range.controls.start.value.getMonth(), this.range.controls.start.value.getDate(), 0, 0, 0));
    this.data.arrivalTo = new Date(Date.UTC(this.range.controls.end.value.getFullYear(), this.range.controls.end.value.getMonth(), this.range.controls.end.value.getDate(), 0, 0, 0));
    this.http.post('/CheckpointPass/ChangePass', this.data, {}).subscribe();
  }

  public checkValidInput() {
    if (this.data.governmentNumber.length != 8 && this.data.governmentNumber.length != 9) {
      this.warningGosNumber = true;
    } else {
      this.warningGosNumber = false;
    }
  }
}

interface Tabs {
  name: string;
  checkpointPass: CheckpointPass[];
}
